var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('ul', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "my": "20px",
      "pl": "20px"
    }
  }, _vm._l(_vm.value.checkParts, function (item, index) {
    return _c('li', {
      key: 'part' + index
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0), _vm._l(_vm.value.indicators, function (item, index) {
    return _c('c-form-control', {
      key: 'indicators' + index,
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(item.criteria) + " ")])], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }