var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.firstName) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.lastName) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Tengah ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.middleName) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Usia ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.age) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Alasan Konsultasi ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.referralReason) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Tujuan Meal Plan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.mealPlanObjective) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }