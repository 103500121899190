var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Asupan Energi / Energy (kkal/kcal) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.energyIntake) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kebutuhan Energi / Energy (kkal/kcal) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.energyNeeds) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Presentase Kecukupan Energi / Energy (kkal/kcal) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.energyAdequancy) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Asupan Protein sekarang (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.currentProteinIntake) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kebutuhan Protein per Kg per Berat Badan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.proteinNeeds) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kebutuhan Asupan Protein ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.proteinIntakeNeeds) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Konstanta Kebutuhan Protein ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.proteinNeedsConstant) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" % Kecukupan Protein ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.proteinAdequancy) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Asupan Lemak / Fat (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.currentFatIntake) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Konstan Kebutuhan Lemak / Fat (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.fatNeedsConstant) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kebutuhan Asupan Lemak / Fat (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.fatIntakeNeeds) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Presentase Kecukupan Lemak / Fat (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.fatAdequancy) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Asupan Karbohidrat / Carbohydrate (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.currentCarbohydrateIntake) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kebutuhan Karbohidrat / Carbohydrate (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.carbohydrateIntakeNeeds) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Presentase Kecukupan Karbohidrat / Carbohydrate (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.carbohydrateAdequancy) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Asupan Serat / Fiber (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.fiberIntake) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kebutuhan Serat / Fiber (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.fiberNeeds) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Presentase Kecukupan Serat / Fiber (gram) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.fiberAdequancy) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }