var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Berat Badan/Weight (kg) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.weight) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Tinggi Badan/Height (cm) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.height) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" IMT/BMI (kg/m2): ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.bodyMassIndex) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Status Gizi ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.nutritionalStatus) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kebutuhan Energi Bassal (kkal) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.basalEnergyNeeds) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kondisi Keibuan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.maternalCondition) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Tambahan kalori kondisi keibuan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.maternalCalorieAddition) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Pertahankan Hal Ini / What to Maintain ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.maintain) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Perbaiki Hal InI / What to Improve ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.improve) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }