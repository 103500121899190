<template>
  <c-box>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Preferensi makanan/Food preference
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.foodPreference }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Alergi makanan/Food allergies
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.foodAllergies }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Konsumsi cairan/hari (Termasuk minuman selain air mineral)
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.fluidConsumption }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Penyedia makanan/Food preparation provider
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.personServeFood }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Catatan tambahan/Additional notes
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.additionalNote }}
      </c-text>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'FoodNutritionHistory',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>