<template>
  <c-box>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Preskripsi Diet/Nutrition Prescription
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.nutritionPrescription }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Target Pencapaian/Goals (SMART)
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.goal }}
      </c-text>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'NutritionInterventionHistory',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>