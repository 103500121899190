<template>
  <c-box>
    <ul
      v-chakra
      my="20px"
      pl="20px"
    >
      <li
        v-for="(item, index) in value.checkParts"
        :key="'part' + index"
      >
        {{ item }}
      </li>
    </ul>

    <c-form-control
      v-for="(item, index) in value.indicators"
      :key="'indicators' + index"
      mb="18px"
    >
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        {{ item.type }}
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ item.criteria }}
      </c-text>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'NutritionMonitoringHistory',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>