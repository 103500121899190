var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Preskripsi Diet/Nutrition Prescription ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.nutritionPrescription) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Target Pencapaian/Goals (SMART) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.goal) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }