var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-box', {
    attrs: {
      "border-width": "1px",
      "border-color": "primary.400",
      "rounded": "lg",
      "px": ['20px', '45px'],
      "py": ['30px', '50px']
    }
  }, [_vm._l(_vm.steps, function (step, index) {
    return [_vm.stepIndex === index ? _c('CardStep', {
      key: index,
      attrs: {
        "current": index + 1,
        "total": _vm.steps.length,
        "is-prev": index !== 0,
        "is-next": index < _vm.steps.length - 1
      },
      on: {
        "prev": function prev($event) {
          _vm.stepIndex--;
        },
        "next": function next($event) {
          _vm.stepIndex++;
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-weight": "700",
        "mb": "42px"
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]), step.type === 'profile' ? _c('ProfileForm', {
      model: {
        value: _vm.item.profile,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "profile", $$v);
        },
        expression: "item.profile"
      }
    }) : _vm._e(), step.type === 'nutritionAssessment' ? _c('NutritionAssessment', {
      model: {
        value: _vm.item.nutritionAssessment,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "nutritionAssessment", $$v);
        },
        expression: "item.nutritionAssessment"
      }
    }) : _vm._e(), step.type === 'physicalActivityHistory' ? _c('PhysicalActivityHistory', {
      model: {
        value: _vm.item.physicalActivityHistory,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "physicalActivityHistory", $$v);
        },
        expression: "item.physicalActivityHistory"
      }
    }) : _vm._e(), step.type === 'calorieMacroNutrientIntake' ? _c('CalorieMacroNutrientIntake', {
      model: {
        value: _vm.item.calorieMacroNutrientIntake,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "calorieMacroNutrientIntake", $$v);
        },
        expression: "item.calorieMacroNutrientIntake"
      }
    }) : _vm._e(), step.type === 'foodNutritionHistory' ? _c('FoodNutritionHistory', {
      model: {
        value: _vm.item.foodNutritionHistory,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "foodNutritionHistory", $$v);
        },
        expression: "item.foodNutritionHistory"
      }
    }) : _vm._e(), step.type === 'bodyCompositionMeasure' ? _c('BodyCompositionMeasure', {
      model: {
        value: _vm.item.bodyCompositionMeasure,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "bodyCompositionMeasure", $$v);
        },
        expression: "item.bodyCompositionMeasure"
      }
    }) : _vm._e(), step.type === 'biochemicalData' ? _c('BiochemicalData', {
      model: {
        value: _vm.item.biochemicalData,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "biochemicalData", $$v);
        },
        expression: "item.biochemicalData"
      }
    }) : _vm._e(), step.type === 'primaryNutritionDiagnosis' ? _c('PrimaryNutritionDiagnosis', {
      model: {
        value: _vm.item.primaryNutritionDiagnosis,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "primaryNutritionDiagnosis", $$v);
        },
        expression: "item.primaryNutritionDiagnosis"
      }
    }) : _vm._e(), step.type === 'nutritionIntervention' ? _c('NutritionIntervention', {
      model: {
        value: _vm.item.nutritionIntervention,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "nutritionIntervention", $$v);
        },
        expression: "item.nutritionIntervention"
      }
    }) : _vm._e(), step.type === 'nutritionMonitoring' ? _c('NutritionMonitoring', {
      model: {
        value: _vm.item.nutritionMonitoring,
        callback: function callback($$v) {
          _vm.$set(_vm.item, "nutritionMonitoring", $$v);
        },
        expression: "item.nutritionMonitoring"
      }
    }) : _vm._e()], 1) : _vm._e()];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }