var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nilai Pengukuran Gula Darah Sewaktu ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.bloodSugar) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Standar Gula Darah Sewaktu ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.bloodSugarStandard) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kesimpulan Gula Darah Sewaktu ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.bloodSugarConclusion) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nilai Pengukuran Total Kolesterol ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrol) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Standar Total Kolesterol ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrolStandard) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nilai Pengukuran Kolesterol HDL ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrolHDL) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kesimpulan Total Kolesterol ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrolConclusion) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Standar Kolesterol HDL ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrolHDLStandard) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kesimpulan Kolesterol HDL ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrolHDLConclusion) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nilai Pengukuran Kolesterol LDL ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrolLDL) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Standar Kolesterol LDL ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrolLDLStandard) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kesimpulan Kolesterol LDL ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.colestrolLDLConclusion) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nilai Pengukuran Asam Urat ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.uricAcid) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Standar Asam Urat ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.uricAcidStandard) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kesimpulan Asam Urat ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.uricAcidConclusion) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nutrition-Focused Physical Findings ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.nutritionFocusedPhysicalFinding) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "5px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Client History ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.clientHistory) + " ")])], 1), _vm._l(_vm.value.others, function (item, index) {
    return _c('c-form-control', {
      key: 'indicators' + index,
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" " + _vm._s(item.field) + " ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")])], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }