<template>
  <c-box>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Nilai Pengukuran Lingkar Pinggang (cm)
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.waistSize }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Standar Lingkar Pinggang
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.waistSizeStandard }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kesimpulan Lingkar Pinggang
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.waistSizeConclusion }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Nilai Pengukuran % Lemak Tubuh
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.fat }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Standar % Lemak Tubuh
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.fatStandard }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kesimpulan % Lemak Tubuh
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.fatConclusion }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Nilai Pengukuran Visceral Fat
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.visceralFat }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Standar Visceral Fat
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.visceralFatStandard }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kesimpulan Visceral Fat
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.visceralFatConclusion }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Nilai Pengukuran Massa Otot
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.muscleMass }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Standar Massa Otot
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.muscleMassStandard }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="primary.400"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kesimpulan Massa Otot
      </c-text>
      <c-text
        color="superDarkGray.900"
        font-size="18px"
        line-height="27px"
      >
        {{ value.muscleMassConclusion }}
      </c-text>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'BodyCompositionMeasureHistory',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>